import { Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { rem } from 'polished';

interface Props extends FlexProps {
  quantity: number;
  label?: string;
}

export const QuantityLabel = ({ quantity, label = 'items', ...props }: Props) => (
  <Tooltip
    label={`${Intl.NumberFormat('en').format(quantity)} ${label}`}
    fontSize="md"
    gutter={16}
    placement="top"
    hasArrow
    arrowSize={8}
    isDisabled={quantity < 1000}
  >
    <Flex
      align="center"
      justify="center"
      fontSize="xs"
      p="space.6"
      lineHeight={1}
      bg="gray.4"
      rounded="full"
      {...(quantity < 10 ? { boxSize: rem(24) } : { px: 'space.8' })}
      {...props}
    >
      {`x${Intl.NumberFormat('en', { notation: 'compact' }).format(quantity)}`}
    </Flex>
  </Tooltip>
);
