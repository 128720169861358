import { Box, chakra } from '@chakra-ui/react';
import { useMeasure } from '@react-hookz/web';
import { Backlight } from '@sphere/ui';
import React, {
  AudioHTMLAttributes,
  CSSProperties,
  ComponentPropsWithoutRef,
  FC,
  IframeHTMLAttributes,
  ReactElement,
  RefObject,
  SyntheticEvent,
  VideoHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Address, erc721Abi } from 'viem';
import { useReadContract } from 'wagmi';
import { ThemeContext } from '../../ReservoirKitProvider';
import { erc1155ABI } from '../../constants/abis';
import { useModelViewer, useTokens } from '../../hooks';
import { convertTokenUriToImage } from '../../lib/processTokenURI';
import { Loader } from '../../primitives';
import MediaPlayButton from './MediaPlayButton';
import TokenFallback from './TokenFallback';

type MediaType =
  | 'mp4'
  | 'mp3'
  | 'wav'
  | 'm4a'
  | 'mov'
  | 'gltf'
  | 'glb'
  | 'png'
  | 'jpeg'
  | 'jpg'
  | 'svg'
  | 'gif'
  | 'html'
  | 'other'
  | undefined;

export const extractMediaType = (token?: RequiredTokenProps): MediaType | null => {
  let extension: string | null = null;

  // Extract extension from media url
  if (token?.media) {
    const pieces = token.media.split('/');
    const file = pieces[pieces.length - 1] ?? null;
    const matches = file?.match('(\\.[^.]+)$') ?? null;
    extension = matches?.[0]?.replace('.', '') ?? null;
  }

  // Extract extension from mediaMimeType if not found in media url. Ensures both strings
  // like `text/html` and `text/html; charset=utf-8` are handled correctly.
  if (token?.media && !extension) {
    const mimeType = token?.metadata?.mediaMimeType as string | undefined;
    extension = mimeType?.match(/.*\/(.*?)(;|$)/)?.[1] ?? null;
  }

  return (extension as MediaType) ? (extension as MediaType) : null;
};

type Token = NonNullable<NonNullable<ReturnType<typeof useTokens>['data']>['0']>['token'];

type RequiredTokenProps = Pick<
  NonNullable<Token>,
  | 'image'
  | 'media'
  | 'collection'
  | 'tokenId'
  | 'imageSmall'
  | 'imageMedium'
  | 'imageLarge'
  | 'kind'
  | 'metadata'
>;

type Props = {
  token?: RequiredTokenProps;
  staticOnly?: boolean;
  imageResolution?: 'small' | 'medium' | 'large';
  style?: CSSProperties;
  hoverStyle?: CSSProperties;
  className?: string;
  modelViewerOptions?: any;
  videoOptions?: VideoHTMLAttributes<HTMLVideoElement>;
  audioOptions?: AudioHTMLAttributes<HTMLAudioElement>;
  iframeOptions?: IframeHTMLAttributes<HTMLIFrameElement>;
  disableOnChainRendering?: boolean;
  chainId?: number;
  fallbackMode?: ComponentPropsWithoutRef<typeof TokenFallback>['mode'];
  withBacklight?: boolean;
  fallback?: (mediaType: MediaType | null) => ReactElement | null;
  onError?: (e: Event) => void;
  onRefreshToken?: () => void;
};

const TokenMedia: FC<Props> = ({
  token,
  staticOnly,
  imageResolution,
  style,
  hoverStyle,
  className,
  modelViewerOptions = {},
  videoOptions = {},
  audioOptions = {},
  iframeOptions = {},
  disableOnChainRendering,
  chainId,
  fallbackMode,
  withBacklight = false,
  fallback,
  onError = () => {},
  onRefreshToken = () => {},
}) => {
  const mediaRef = useRef<HTMLAudioElement | HTMLVideoElement>(null);
  const themeContext = useContext(ThemeContext);
  let borderRadius: string = themeContext?.radii?.borderRadius?.value || '0';
  const [error, setError] = useState<SyntheticEvent | Event | null>(null);
  const media = token?.media;
  const tokenImage = (() => {
    switch (imageResolution) {
      case 'small':
        return token?.imageSmall;
      case 'medium':
        return token?.imageMedium;
      case 'large':
        return token?.imageLarge;
      default:
        return token?.image;
    }
  })();
  const mediaType = extractMediaType(token);
  const defaultStyle: CSSProperties = {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    borderRadius,
    position: 'relative',
  };
  const computedStyle = {
    ...defaultStyle,
    ...style,
  };

  useModelViewer(
    !staticOnly && mediaType && (mediaType === 'gltf' || mediaType === 'glb') ? true : false,
  );

  const [measurements, containerRef] = useMeasure<HTMLDivElement>();
  const isContainerLarge = (measurements?.width || 0) >= 360;

  const contract = token?.collection?.id?.split(':')[0] as Address;

  const [onChainImage, setOnChainImage] = useState('');
  const [onChainImageBroken, setOnChainImageBroken] = useState(false);
  const [isUpdatingOnChainImage, setIsUpdatingOnChainImage] = useState(false);

  const is1155 = token?.kind === 'erc1155';

  const {
    data: tokenURI,
    isLoading: isFetchingTokenURI,
    isError: fetchTokenURIError,
  } = useReadContract(
    !disableOnChainRendering && (error || (!media && !tokenImage))
      ? {
          address: contract,
          abi: is1155 ? erc1155ABI : erc721Abi,
          functionName: is1155 ? 'uri' : 'tokenURI',
          args: token?.tokenId ? [BigInt(token?.tokenId)] : undefined,
          chainId: chainId,
        }
      : undefined,
  );

  useEffect(() => {
    if (tokenURI) {
      setIsUpdatingOnChainImage(true);
      (async () => {
        const updatedOnChainImage = await convertTokenUriToImage(tokenURI);
        setOnChainImage(updatedOnChainImage);
      })().then(() => {
        setIsUpdatingOnChainImage(false);
      });
    }
  }, [tokenURI]);

  useEffect(() => {
    if (mediaRef && mediaRef.current) {
      mediaRef.current.load();
    }
  }, [media]);

  if (!token && !staticOnly) {
    console.warn('A token object or a media url are required!');
    return null;
  }

  if (error || (!media && !tokenImage)) {
    if (!disableOnChainRendering && !onChainImageBroken && !fetchTokenURIError) {
      return (
        <Box
          _groupHover={hoverStyle}
          _groupFocusWithin={hoverStyle}
          transition="transform .2s ease-in-out"
        >
          {isFetchingTokenURI || isUpdatingOnChainImage ? (
            <Loader style={{ ...computedStyle }} />
          ) : (
            <img
              src={onChainImage}
              style={computedStyle}
              alt="Token Image"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                setOnChainImageBroken(true);
              }}
            />
          )}
        </Box>
      );
    }

    let fallbackElement: ReactElement | null | undefined;
    if (fallback) {
      fallbackElement = fallback(mediaType);
    }
    if (!fallbackElement) {
      fallbackElement = (
        <Box
          _groupHover={hoverStyle}
          _groupFocusWithin={hoverStyle}
          transition="transform .2s ease-in-out"
        >
          <TokenFallback
            style={style}
            className={className}
            token={token}
            mode={fallbackMode}
            onRefreshClicked={onRefreshToken}
          />
        </Box>
      );
    }
    return fallbackElement;
  }

  const onErrorCb = (e: SyntheticEvent) => {
    setError(e);
    onError(e.nativeEvent);
  };

  if (staticOnly || !media) {
    return (
      <chakra.img
        alt="Token Image"
        src={tokenImage}
        _groupHover={hoverStyle}
        _groupFocusWithin={hoverStyle}
        style={{
          ...computedStyle,
          visibility: !tokenImage || tokenImage.length === 0 ? 'hidden' : 'visible',
        }}
        className={className}
        onError={onErrorCb}
      />
    );
  }

  // VIDEO
  if (mediaType === 'mp4' || mediaType === 'mov') {
    return (
      <Box
        as={withBacklight ? Backlight : Box}
        {...(withBacklight && {
          videoRef: mediaRef as RefObject<HTMLVideoElement>,
        })}
      >
        {!isContainerLarge && <MediaPlayButton mediaRef={mediaRef} />}
        <Box
          className={className}
          style={computedStyle}
          ref={containerRef}
          _groupHover={hoverStyle}
          _groupFocusWithin={hoverStyle}
        >
          <video
            style={computedStyle}
            className={className}
            poster={tokenImage}
            {...videoOptions}
            controls={isContainerLarge}
            loop
            playsInline
            onError={onErrorCb}
            ref={mediaRef as RefObject<HTMLVideoElement>}
          >
            <source src={media} type="video/mp4" />
            Your browser does not support the
            <code>video</code> element.
          </video>
        </Box>
      </Box>
    );
  }

  // AUDIO
  if (mediaType === 'wav' || mediaType === 'mp3' || mediaType === 'm4a') {
    return (
      <Box>
        {!isContainerLarge && <MediaPlayButton mediaRef={mediaRef} />}
        <Box
          className={className}
          style={computedStyle}
          ref={containerRef}
          _groupHover={hoverStyle}
          _groupFocusWithin={hoverStyle}
        >
          <img
            alt="Audio Poster"
            src={tokenImage}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              visibility: !tokenImage || tokenImage.length === 0 ? 'hidden' : 'visible',
            }}
            onError={onErrorCb}
          />
          <audio
            src={media}
            {...audioOptions}
            onError={onErrorCb}
            ref={mediaRef}
            controls={isContainerLarge}
            style={{
              position: 'absolute',
              bottom: 16,
              left: 16,
              width: 'calc(100% - 32px)',
            }}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </Box>
      </Box>
    );
  }

  // 3D
  if (mediaType === 'gltf' || mediaType === 'glb') {
    return (
      <Box
        _groupHover={hoverStyle}
        _groupFocusWithin={hoverStyle}
        transition="transform .2s ease-in-out"
      >
        <model-viewer
          src={media}
          ar
          ar-modes="webxr scene-viewer quick-look"
          _groupHover={hoverStyle}
          _groupFocusWithin={hoverStyle}
          poster={tokenImage}
          seamless-poster
          shadow-intensity="1"
          camera-controls
          enable-pan
          {...modelViewerOptions}
          style={computedStyle}
          className={className}
          onError={onErrorCb}
        ></model-viewer>
      </Box>
    );
  }

  //Image
  if (mediaType === 'png' || mediaType === 'jpeg' || mediaType === 'jpg' || mediaType === 'gif') {
    return (
      <chakra.img
        alt="Token Image"
        src={media}
        _groupHover={hoverStyle}
        _groupFocusWithin={hoverStyle}
        className={className}
        style={{
          ...computedStyle,
          visibility: !media || media.length === 0 ? 'hidden' : 'visible',
        }}
        onError={onErrorCb}
      />
    );
  }

  // HTML
  if (
    mediaType === 'html' ||
    mediaType === null ||
    mediaType === undefined ||
    mediaType === 'other' ||
    mediaType === 'svg'
  ) {
    return (
      <chakra.iframe
        _groupHover={hoverStyle}
        _groupFocusWithin={hoverStyle}
        style={computedStyle}
        className={className}
        src={media}
        sandbox="allow-scripts"
        frameBorder="0"
        {...iframeOptions}
      ></chakra.iframe>
    );
  }

  return (
    <chakra.img
      alt="Token Image"
      src={tokenImage}
      style={{
        ...computedStyle,
        visibility: !tokenImage || tokenImage.length === 0 ? 'hidden' : 'visible',
      }}
      _groupHover={hoverStyle}
      _groupFocusWithin={hoverStyle}
      className={className}
      onError={onErrorCb}
    />
  );
};

export default TokenMedia;
