import { TableColumnHeaderProps, Text, Th } from '@chakra-ui/react';
import { SortDirectionAllIcon, SortDirectionAscIcon, SortDirectionDescIcon } from '@sphere/icons';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';
import { AllOrNone } from '../../types/helpers';

/**
 * Used as a base type for sort direction, although implicit it should be noted that these enum
 * values match those exported by our frontend graphql client
 */
type SortDirection = 'ASC' | 'DESC' | null | undefined;

type Props<S> = Omit<TableColumnHeaderProps, 'onClick'> &
  AllOrNone<{
    isSortable: boolean;
    sortDirection: S;
    onSort(): void;
  }>;

const StyledTh = ({ children, ...props }: PropsWithChildren<TableColumnHeaderProps>) => (
  <Th
    {...props}
    fontWeight="normal"
    fontSize="sm"
    textTransform="unset"
    bg="blackBg"
    outline="none"
    border="none"
    height={rem(40)}
    position="relative"
    _after={{
      content: '""',
      position: 'absolute',
      height: rem(40),
      width: 2,
      right: -2,
      top: 0,
      bg: 'blackBg',
    }}
  >
    {children}
  </Th>
);

const SortIcon = <S extends SortDirection>({
  sortDirection,
}: Pick<Props<S>, 'sortDirection'>): JSX.Element | null => {
  if (!sortDirection) return <SortDirectionAllIcon flexShrink={0} width={rem(8)} ml="space.8" />;

  return (
    {
      ASC: <SortDirectionAscIcon flexShrink={0} width={rem(8)} ml="space.8" />,
      DESC: <SortDirectionDescIcon flexShrink={0} width={rem(8)} ml="space.8" />,
    }[sortDirection] ?? null
  );
};

export const TableHeader = <S extends SortDirection>({
  isSortable = false,
  sortDirection,
  onSort,
  children,
  width = 'auto',
  ...tableColumnHeaderProps
}: PropsWithChildren<Props<S>>) => (
  <StyledTh
    as="th"
    onClick={isSortable ? onSort : undefined}
    cursor={isSortable ? 'pointer' : 'inherit'}
    width={width}
    {...tableColumnHeaderProps}
  >
    <Text display="inline-flex" alignItems="center">
      {children}
      {isSortable && <SortIcon sortDirection={sortDirection} />}
    </Text>
  </StyledTh>
);
