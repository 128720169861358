import { ReservoirAction } from '@/utils/reservoir';
import { useMarketplaceChain } from './use-marketplace-chain';

const useChainActions = (actions: ReservoirAction[]): boolean[] => {
  const marketplaceChain = useMarketplaceChain();

  return actions.map(action => !marketplaceChain.disabledActions?.includes(action));
};

export default useChainActions;
