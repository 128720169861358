import { ResponsiveValue, Skeleton, Stack, Td, Tr } from '@chakra-ui/react';

type Props = {
  /** Defines the number of rows to render */
  rows: number;
  /** Set this to the number of columns in your table to ensure the loader spans the full width of the table */
  cols?: number;
  /** Defines the height of each row. Tweak to match the rows when containing data */
  height: ResponsiveValue<string | number>;
  /** Defines if a mobile variant should be shown. */
  isMobile?: boolean;
};

export const TableSkeletonRows = ({ height, rows = 1, cols = 1, isMobile = false }: Props) =>
  isMobile ? (
    <Stack direction="column" spacing="space.8">
      {Array.from(Array(rows).keys()).map(i => (
        <Skeleton key={i} height={height} w="full" />
      ))}
    </Stack>
  ) : (
    <>
      {Array.from(Array(rows).keys()).map(i => (
        <Tr key={i}>
          <Td colSpan={cols} p={0}>
            <Skeleton height={height} w="full" />
          </Td>
        </Tr>
      ))}
    </>
  );
