import { TableCellProps, Td, Text } from '@chakra-ui/react';
import { formatNumber } from '@onbeam/utils';

type Props = {
  value?: number;
} & TableCellProps;

export const ChangePercentageTableCell = ({ value, ...tableCellProps }: Props) => {
  const percentage = value ? (value - 1) * 100 : null;
  const isPositive = (percentage || 0) > 0;

  return (
    <Td {...tableCellProps}>
      <Text color={!percentage || percentage === 0 ? 'white' : isPositive ? 'green' : 'red'}>
        {formatNumber(percentage || undefined, { suffix: '%', fallback: '--' })}
      </Text>
    </Td>
  );
};
