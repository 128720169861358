import { useAuthentication } from '@/hooks/useAuthentication';
import { PropsWithChildren, cloneElement } from 'react';

type Props = {
  trigger: JSX.Element;
};

export const AuthenticationTriggerWrapper = ({ trigger, children }: PropsWithChildren<Props>) => {
  const { authenticate, isAuthenticated } = useAuthentication();

  if (isAuthenticated) return children;

  return cloneElement(trigger, {
    onClick: authenticate,
  });
};
