import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { rem } from 'polished';
import { Fragment, isValidElement } from 'react';
import { LinkConnector } from '../LinkConnector';
import { QuantityLabel } from '../QuantityLabel';

export type TableRowMediaItemProps = {
  title: string;
  imageSrc?: string;
  subtitle?: string | React.ReactNode;
  href?: string;
  imageAlt?: string;
  coverImage?: boolean;
  rounded?: string;
  quantity?: number;
  hideLinkOnSubtitle?: boolean;
};

export const TableRowMediaItem = ({
  title,
  subtitle,
  imageSrc,
  href,
  imageAlt = '',
  coverImage,
  rounded = 'base',
  quantity = 1,
  hideLinkOnSubtitle = false,
}: TableRowMediaItemProps) => {
  const Wrapper = href && !hideLinkOnSubtitle ? LinkConnector : Fragment;
  const LinkWrapper = href && hideLinkOnSubtitle ? Link : Fragment;
  const LinkItemWrapper = href && hideLinkOnSubtitle ? LinkConnector : Fragment;

  return (
    <Wrapper {...(href && !hideLinkOnSubtitle && { href })}>
      <Flex
        alignItems="center"
        {...(href &&
          !hideLinkOnSubtitle && {
            as: 'a',
            rounded: 'base',
            transition: 'opacity 120ms ease-in-out',
            _hover: { opacity: '0.5' },
          })}
        _focus={{ outline: 'none' }}
        _focusVisible={{ boxShadow: 'outline' }}
      >
        <LinkItemWrapper {...(href && hideLinkOnSubtitle && { href })}>
          <LinkWrapper>
            <Image
              flexShrink={0}
              rounded={rounded}
              bg="gray.4"
              boxSize="100%"
              objectFit={coverImage ? 'cover' : 'contain'}
              height={rem(44)}
              width={rem(44)}
              src={imageSrc}
              alt={imageAlt}
              fallback={<Box bg="gray.4" height={rem(44)} width={rem(44)} rounded={rounded} />}
            />
          </LinkWrapper>
        </LinkItemWrapper>
        <Box lineHeight="short" ml="space.12">
          <LinkItemWrapper {...(href && hideLinkOnSubtitle && { href })}>
            <Flex
              gap="space.8"
              {...(href &&
                hideLinkOnSubtitle && {
                  as: 'a',
                  transition: 'opacity 120ms ease-in-out',
                  _hover: { opacity: '0.5' },
                })}
            >
              <Text fontSize="md" noOfLines={1} wordBreak="break-all">
                {title}
              </Text>
              {quantity > 1 && <QuantityLabel quantity={quantity} />}
            </Flex>
          </LinkItemWrapper>

          {subtitle && typeof subtitle === 'string' && (
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              color="gray.4"
              noOfLines={1}
              mt={quantity > 1 ? 'space.4' : 0}
              wordBreak="break-all"
            >
              {subtitle}
            </Text>
          )}

          {subtitle && isValidElement(subtitle) && <>{subtitle}</>}
        </Box>
      </Flex>
    </Wrapper>
  );
};
