import { chakra, usePrefersReducedMotion } from '@chakra-ui/react';
import { PropsWithChildren, RefObject, useCallback, useEffect, useRef } from 'react';

type Props = {
  videoRef: RefObject<HTMLVideoElement>;
};

let id: number | undefined = undefined;

export const Backlight = ({ children, videoRef }: PropsWithChildren<Props>) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const repaintBacklight = useCallback(() => {
    if (!id) return;
    id = undefined;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    startBacklight();
  }, [videoRef, canvasRef]);

  const startBacklight = useCallback(() => {
    if (id) return;
    id = requestAnimationFrame(repaintBacklight);
  }, [videoRef, canvasRef]);

  const pauseBacklight = useCallback(() => {
    if (!id) return;
    cancelAnimationFrame(id);
    id = undefined;
  }, [videoRef, canvasRef, id]);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !canvas || prefersReducedMotion) return;

    video.addEventListener('play', startBacklight);
    video.addEventListener('pause', pauseBacklight);
    video.addEventListener('ended', pauseBacklight);
    video.addEventListener('seeked', repaintBacklight);
    video.addEventListener('load', repaintBacklight);

    return () => {
      // Unsubscribe to all events on unmount
      video.addEventListener('play', startBacklight);
      video.addEventListener('pause', pauseBacklight);
      video.addEventListener('ended', pauseBacklight);
      video.addEventListener('seeked', repaintBacklight);
      video.addEventListener('load', repaintBacklight);
    };
  }, [videoRef]);

  return (
    <chakra.div position="relative">
      <chakra.canvas
        ref={canvasRef}
        inset="0"
        position="absolute"
        transform="scale(1.1) translateZ(0)"
        width="100%"
        height="100%"
        filter="auto"
        blur="80px"
        saturate="125%"
        opacity={0.5}
      />
      {children}
    </chakra.div>
  );
};
