import { Skeleton, useMediaQuery, useTheme, VStack } from '@chakra-ui/react';
import { rem } from 'polished';

export const TableEmptyRows = () => {
  const { breakpoints } = useTheme();
  const [isSmallerThanMd] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  return (
    <VStack
      width="100%"
      overflow="hidden"
      position="relative"
      _before={{
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, #13131300 0, #131313 70%)',
        zIndex: 1,
      }}
    >
      {Array.from(Array(isSmallerThanMd ? 2 : 3).keys()).map(key => (
        <Skeleton
          key={`acount-activity-skeleton-${key}`}
          speed={0}
          height={{
            base: rem(205),
            md: rem(80),
          }}
          width="100%"
          borderRadius="radius.8"
          mb="space.8"
          endColor="gray.2"
          opacity={1}
        />
      ))}
    </VStack>
  );
};
