import { DATE_FORMAT, TIME_FORMAT } from '@/config/date';
import { TableCellProps, Td, Tooltip } from '@chakra-ui/react';
import { format, formatDistance, parseISO } from 'date-fns';
import dynamic from 'next/dynamic';

interface Props extends TableCellProps {
  date: string;
  addSuffix?: boolean;
  includeSeconds?: boolean;
  prefix?: string;
}

const DateTableCell = ({
  date,
  addSuffix = true,
  includeSeconds = false,
  prefix,
  ...props
}: Props) => (
  <Td
    sx={{ '& span:focus': { outline: 'none' }, '& span:focus-visible': { boxShadow: 'outline' } }}
    {...props}
  >
    <Tooltip
      label={format(parseISO(date), `${DATE_FORMAT}${TIME_FORMAT}`)}
      fontSize="md"
      gutter={16}
      placement="top"
      hasArrow
      arrowSize={8}
    >
      {`${prefix || ''}${formatDistance(parseISO(date), new Date(), {
        addSuffix,
        includeSeconds,
      })}`}
    </Tooltip>
  </Td>
);

/**
 * We disable SSR for this component since there is a small delay between rendering on the server
 * and the client. This can cause hydration errors because of a mismatch between the two timestamps.
 */
export default dynamic(() => Promise.resolve(DateTableCell), {
  ssr: false,
});
