import { Box, Flex, TableCellProps, Td, Text, Tooltip } from '@chakra-ui/react';
import { formatTokenAmount } from '@onbeam/utils';
import { QuantityLabel } from '@sphere/ui';
import { ReactElement } from 'react';
import { UsdPrice } from '../UsdPrice/UsdPrice';

interface Props extends TableCellProps {
  amount?: number;
  currency?: string | null;
  usdPrice?: number;
  icon?: ReactElement;
  quantity?: number;
  align?: 'left' | 'right';
}

export const PriceTableCell = ({
  usdPrice,
  amount,
  currency,
  icon,
  quantity,
  align = 'left',
  ...props
}: Props) => (
  <Td textAlign={align} {...props}>
    <Flex align="center" justify={align === 'left' ? 'flex-start' : 'flex-end'} gap="space.14">
      {icon}
      {!!amount && currency ? (
        <Box>
          <Tooltip
            label={formatTokenAmount(amount, {
              symbol: currency,
              notation: 'standard',
              maximumFractionDigits: 4,
            })}
            fontSize="md"
            gutter={16}
            placement="top"
            hasArrow
            arrowSize={8}
          >
            <Text>{formatTokenAmount(amount, { symbol: currency })}</Text>
          </Tooltip>
          {!!usdPrice && (
            <UsdPrice
              usdValue={usdPrice}
              isExact
              color="gray.1"
              fontSize="xs"
              display="block"
              lineHeight="lg"
            />
          )}
        </Box>
      ) : (
        '--'
      )}
      {!!quantity && <QuantityLabel quantity={quantity} />}
    </Flex>
  </Td>
);
