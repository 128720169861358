import useChainActions from '@/hooks/use-chain-actions';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { ReservoirAction } from '@/utils/reservoir';
import { Tooltip } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement, cloneElement, forwardRef } from 'react';

type Props = {
  action: ReservoirAction;
  children: ReactElement;
};

const ActionDisabledTooltip = forwardRef<ReactElement, Props>(
  ({ action, children, ...props }, ref) => {
    const { t } = useTranslation('common');
    const { activeConnector } = useAuthentication();
    const marketplaceChain = useMarketplaceChain();
    const [actionEnabled] = useChainActions([action]);

    if (!children) return null;

    const isUnsupportedConnector =
      activeConnector && !marketplaceChain.supportedConnectors.includes(activeConnector.id);

    const isEnabled = !isUnsupportedConnector && actionEnabled;

    const Trigger = cloneElement(children, {
      ref,
      ...(!isEnabled ? { isDisabled: true, disabled: true } : undefined),
      ...props,
    });

    return (
      <Tooltip
        isDisabled={isEnabled}
        label={
          actionEnabled && isUnsupportedConnector
            ? t('action.unsupported-connector', {
                connector: activeConnector?.name,
              })
            : t('action.coming-soon', { chain: marketplaceChain.name })
        }
        placement="top"
        hasArrow
        arrowSize={8}
      >
        {Trigger}
      </Tooltip>
    );
  },
);

export default ActionDisabledTooltip;
