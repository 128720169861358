import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={6}
    fill="none"
    viewBox="0 0 10 6"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.795 5.53a.827.827 0 01-1.17 0L.662 1.567a.827.827 0 011.17-1.17L5.21 3.775 8.588.397a.827.827 0 011.17 1.17L5.795 5.53z"
      clipRule="evenodd"
    />
  </Icon>
));
